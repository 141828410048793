var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var LeaveCaseService = /** @class */ (function () {
    function LeaveCaseService() {
    }
    /**
     * 留案案件查询
     */
    LeaveCaseService.prototype.queryLeaveCases = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.leaveCaseController.queryLeaveCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                caseNumber: data.caseNumber,
                cardNo: data.cardNo,
                phone: data.phone,
                stage: data.stage,
                collectStatus: data.collectStatus,
                caseStatus: data.caseStatus,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal ? data.overdueAmtTotal.min : null,
                overdueAmtTotalEnd: data.overdueAmtTotal ? data.overdueAmtTotal.max : null,
                leftAmtStart: data.leftAmt ? data.leftAmt.min : null,
                leftAmtEnd: data.leftAmt ? data.leftAmt.max : null,
                paymentStatus: data.paymentStatus,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                comment: data.comment,
                currentCollectorName: data.currentCollectorName,
                departCode: data.departCode,
                overduePeriodsStart: data.overduePeriods ? data.overduePeriods.min : null,
                overduePeriodsEnd: data.overduePeriods ? data.overduePeriods.max : null,
                overdueDaysStart: data.overdueDays ? data.overdueDays.min : null,
                overdueDaysEnd: data.overdueDays ? data.overdueDays.max : null,
                city: data.city,
                caseLeaveFlag: data.caseLeaveFlag,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                caseType: data.caseType,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                phones: data.phones ? data.phones.split(",") : null,
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], LeaveCaseService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], LeaveCaseService.prototype, "queryLeaveCases", null);
    return LeaveCaseService;
}());
export { LeaveCaseService };
